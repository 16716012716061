<template>
    <div id="pageContainer">
        <SpinnerOverlay v-if="!debug" class="z-index-999" />
        <b-button id="printReportBtn" v-if="debug" @click="printReport">Print Report</b-button>

        <div id="generatingReportContainer" v-if="!debug">
            <h1 id="generatingReport" class="text-primary">{{ t('generatingReport') }}</h1>
        </div>

        <div v-if="preOpReport" id="main">
            <div id="pageHeader">
                <img id="headerImg" src="@/assets/stella-logo-with-name.png" />
                <div id="headerRight">{{ t('preopreport_CalOrder') }}</div>
            </div>

            <div id="titleContainer" class="d-flex justify-content-between">
                <section>
                    <h2 class="mb-0 font-weight-bold">
                        {{ definedAndNotBlankObj(preOpReport, 'patientOcosId', '—') }}
                        <span class="h5 text-muted">
                            {{ genderHandling(preOpReport) }}
                        </span>
                    </h2>
                    <p class="h6 text-muted">
                        {{ t('patientId') }}
                    </p>
                </section>
                <section v-if="!activeCustomer.hidePatientNames">
                    <h2 class="mb-0 names text-right">
                        {{
                            namesFormatter(
                                definedAndNotBlankObj(preOpReport, 'lastName', ''),
                                definedAndNotBlankObj(preOpReport, 'firstName', '')
                            ) || '&nbsp;'
                        }}
                    </h2>
                    <p
                        v-if="preOpReport.firstName || preOpReport.lastName"
                        class="h6 text-muted text-right"
                    >
                        {{ t('NameLastFirst') }}
                    </p>
                </section>
            </div>

            <div id="patientDataContainer">
                <div id="patientDataCol1">
                    <div class="patientDataItem">
                        <div class="patientDataLabel">{{ t('preopreport_Birthdate') }}</div>
                        <div id="patientDataDOB" class="patientDataValue">
                            {{ date(preOpReport.dob, {isUTC: false}) || '—' }}
                            <span class="noEmphasis">
                                ({{ definedAndNotBlankObj(preOpReport, 'yearsOld', '—') }}
                                {{ t('preopreport_years') }})
                            </span>
                        </div>
                    </div>
                    <div class="patientDataItem">
                        <div class="patientDataLabel">{{ t('preopreport_Customer') }}</div>
                        <div id="patientDataClinicName" class="patientDataValue">
                            {{ definedAndNotBlankObj(preOpReport, 'clinicName', '—') }}
                            <span class="noEmphasis">
                                ({{ definedAndNotBlankObj(preOpReport, 'customerOcosId', '—') }})
                            </span>
                        </div>
                    </div>
                    <div class="patientDataItem">
                        <div class="patientDataLabel">{{ t('preopreport_ODSurgeryDate') }}</div>
                        <div class="patientDataValue">
                            {{ date(preOpReport.odSurgeryDate) || '—' }}
                        </div>
                    </div>
                </div>
                <div id="patientDataCol2">
                    <div class="patientDataItem">
                        <div class="patientDataLabel">{{ t('preopreport_Surgeon') }}</div>
                        <div id="patientDataSurgeon" class="patientDataValue">
                            {{ definedAndNotBlankObj(preOpReport, 'surgeon', '—') }}
                            <span class="noEmphasis">
                                ({{ definedAndNotBlankObj(preOpReport, 'surgeonOcosId', '—') }})
                            </span>
                        </div>
                    </div>
                    <div class="patientDataItem hidden-element">
                        <div class="patientDataLabel">{{ t('preopreport_Customer') }}</div>
                        <div id="patientDataClinicName" class="patientDataValue">
                            {{ definedAndNotBlankObj(preOpReport, 'clinicName', '—') }}
                            <span class="noEmphasis">
                                ({{ definedAndNotBlankObj(preOpReport, 'customerOcosId', '—') }})
                            </span>
                        </div>
                    </div>
                    <div class="patientDataItem">
                        <div class="patientDataLabel">{{ t('preopreport_OSSurgeryDate') }}</div>
                        <div class="patientDataValue">
                            {{ date(preOpReport.osSurgeryDate) || '—' }}
                        </div>
                    </div>
                </div>
            </div>

            <div id="resultsContainer">
                <div id="resultsContainerRow">
                    <div v-for="eye in preOpReport.eyes" :key="eye.name" class="eyeContainer">
                        <div class="eyeResult">
                            <div class="eyeResultHeader">
                                <div id="odEyeTitle" class="eyeTitle">{{ eye.name }}</div>
                                <div
                                    id="odEye"
                                    v-if="eye.name == 'OD'"
                                    class="eyeResultHeaderImgBlack"
                                />

                                <div id="odEye" v-else class="eyeResultHeaderImgGray" />

                                <div
                                    id="sdEye"
                                    v-if="eye.name == 'OS'"
                                    class="eyeResultHeaderImgBlack"
                                />

                                <div id="sdEye" v-else class="eyeResultHeaderImgGray" />

                                <div class="previousInterventionsContainer"></div>

                                <div
                                    v-if="eye.calculationVersion"
                                    class="calculationTitleContainer"
                                >
                                    <div
                                        class="calulationVersionLabel"
                                        v-html="
                                            t('preopreport_CalculatedWith').replace(' ', '&nbsp;')
                                        "
                                    ></div>
                                    <div class="calulationVersionValue">
                                        {{ definedAndNotBlankObj(eye, 'calculationVersion', '—') }}
                                    </div>
                                </div>
                            </div>

                            <div v-if="eye.preop" id="preopContainer" class="sectionContainer">
                                <div class="sectionTitle">{{ t('preopreport_PreOp') }}</div>

                                <div class="sectionTable">
                                    <table id="table1">
                                        <tr class="headerRow th-6">
                                            <th></th>
                                            <th>{{ t('preopreport_SPH') }}</th>
                                            <th>{{ t('preopreport_CYL') }}</th>
                                            <th>{{ t('preopreport_Axis') }}</th>
                                            <th>{{ t('preopreport_BVD') }}</th>
                                            <th>{{ t('preopreport_CL_SPH') }}</th>
                                        </tr>
                                        <tr
                                            :class="
                                                eye.preop.activeRef == 'MAN' && 'selectedCalcRow'
                                            "
                                        >
                                            <td class="noEmphasis preopRowTitle">
                                                {{ t('preopreport_Man_Ref') }}
                                            </td>

                                            <td
                                                v-for="indexNonZero in 5"
                                                :key="indexNonZero"
                                                class="text-nowrap"
                                                v-html="
                                                    generateCell(
                                                        'preop',
                                                        eye.preop.manRef,
                                                        indexNonZero
                                                    )
                                                "
                                            ></td>
                                        </tr>
                                        <tr
                                            v-if="zone != 'OUS'"
                                            :class="
                                                eye.preop.activeRef == 'CYC' && 'selectedCalcRow'
                                            "
                                        >
                                            <td class="noEmphasis preopRowTitle">
                                                {{ t('preopreport_Cyc_Ref') }}
                                            </td>
                                            <td
                                                v-for="indexNonZero in 5"
                                                :key="indexNonZero"
                                                class="text-nowrap"
                                                v-html="
                                                    generateCell(
                                                        'preop',
                                                        eye.preop.cycRef,
                                                        indexNonZero
                                                    )
                                                "
                                            ></td>
                                        </tr>
                                        <tr
                                            :class="
                                                eye.preop.activeRef == 'CLOR' && 'selectedCalcRow'
                                            "
                                        >
                                            <td class="noEmphasis preopRowTitle">
                                                {{ t('preopreport_CL_Over') }}
                                            </td>
                                            <td
                                                v-for="indexNonZero in 5"
                                                :key="indexNonZero"
                                                class="text-nowrap"
                                                v-html="
                                                    generateCell(
                                                        'preop',
                                                        eye.preop.clOver,
                                                        indexNonZero
                                                    )
                                                "
                                            ></td>
                                        </tr>
                                    </table>
                                </div>

                                <div class="sectionData">
                                    <div id="preopDataCol1">
                                        <div class="preopDataItem">
                                            <div class="preopDataLabel">
                                                {{ t('preopreport_K1') }}
                                            </div>
                                            <div id="preopDataFirstName" class="preopDataValue">
                                                {{
                                                    decimalSeparatorFormatter(
                                                        definedAndNotBlankObj(eye.preop, 'k1', '—'),
                                                        decimalSeparator
                                                    )
                                                }}
                                                <span class="noEmphasis">D</span>
                                            </div>
                                        </div>
                                        <div class="preopDataItem">
                                            <div class="preopDataLabel">
                                                {{ t('preopreport_K2') }}
                                            </div>
                                            <div id="preopDataFirstName" class="preopDataValue">
                                                {{
                                                    decimalSeparatorFormatter(
                                                        definedAndNotBlankObj(eye.preop, 'k2', '—'),
                                                        decimalSeparator
                                                    )
                                                }}
                                                <span class="noEmphasis">D</span>
                                            </div>
                                        </div>
                                        <div class="preopDataItem">
                                            <div class="preopDataLabel">
                                                {{ t(`preopreport_${zone}_ACD`) }}
                                            </div>
                                            <div id="preopDataFirstName" class="preopDataValue">
                                                {{
                                                    decimalSeparatorFormatter(
                                                        definedAndNotBlankObj(
                                                            eye.preop,
                                                            'acd',
                                                            '—'
                                                        ),
                                                        decimalSeparator
                                                    )
                                                }}
                                                <span class="noEmphasis">mm</span>
                                            </div>
                                        </div>
                                        <div class="preopDataItem">
                                            <div class="preopDataLabel">
                                                {{ t('preopreport_WW') }}
                                            </div>
                                            <div id="preopDataFirstName" class="preopDataValue">
                                                {{
                                                    decimalSeparatorFormatter(
                                                        definedAndNotBlankObj(eye.preop, 'ww', '—'),
                                                        decimalSeparator
                                                    )
                                                }}
                                                <span class="noEmphasis">mm</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="preopDataCol2">
                                        <div class="preopDataItem">
                                            <div class="preopDataLabel">
                                                {{ t('preopreport_K1_Axis') }}
                                            </div>
                                            <div id="preopDataFirstName" class="preopDataValue">
                                                {{
                                                    definedAndNotBlankObj(eye.preop, 'k1Axis', '—')
                                                }}
                                                <span class="noEmphasis degAdjustment">&deg;</span>
                                            </div>
                                        </div>
                                        <div class="preopDataItem">
                                            <div class="preopDataLabel">
                                                {{ t('preopreport_K2_Axis') }}
                                            </div>
                                            <div id="preopDataFirstName" class="preopDataValue">
                                                {{
                                                    definedAndNotBlankObj(eye.preop, 'k2Axis', '—')
                                                }}
                                                <span class="noEmphasis degAdjustment">&deg;</span>
                                            </div>
                                        </div>
                                        <div class="preopDataItem">
                                            <div class="preopDataLabel">
                                                {{ t('preopreport_CCT') }}
                                            </div>
                                            <div id="preopDataFirstName" class="preopDataValue">
                                                {{
                                                    handleForMicronConversion(
                                                        definedAndNotBlankObj(eye.preop, 'cct', '—')
                                                    )
                                                }}
                                                <span class="noEmphasis">µm</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-if="eye.calculation"
                                id="calculationContainer"
                                class="sectionContainer"
                            >
                                <div class="sectionTitle">{{ t('preopreport_Calculation') }}</div>

                                <div class="sectionTable">
                                    <table>
                                        <tr>
                                            <th class="calculationTableHeader1" colspan="2">
                                                {{ t('preopreport_SELECTED') }}
                                            </th>
                                            <th id="whiteSpacer"></th>
                                            <th class="calculationTableHeader1" colspan="4">
                                                {{ t('preopreport_EXPECTED') }}
                                            </th>
                                        </tr>
                                        <tr class="headerRow th-7">
                                            <th>{{ t('preopreport_Sphere') }}</th>
                                            <th>{{ t('preopreport_Cylinder') }}</th>
                                            <th></th>
                                            <th>{{ t('preopreport_Sphere') }}</th>
                                            <th>{{ t('preopreport_Cylinder') }}</th>
                                            <th>{{ t('preopreport_Axis') }}</th>
                                            <th>{{ t('preopreport_SEQ') }}</th>
                                        </tr>
                                        <!-- eslint-disable vue/no-use-v-if-with-v-for -->
                                        <tr
                                            v-if="
                                                eye.calculation.calculationTable != undefined &&
                                                eye.calculation.calculationTable.length > 0
                                            "
                                            v-for="index in 5"
                                            :key="index"
                                            :class="
                                                onScreenRowSelectionIndex(eye.calculation) == index
                                                    ? 'selectedCalcRow'
                                                    : ''
                                            "
                                        >
                                            <!-- eslint-enable vue/no-use-v-if-with-v-for -->
                                            <td
                                                v-for="indexNonZero in 2"
                                                :key="indexNonZero"
                                                class="calcRowCell text-nowrap"
                                                v-html="
                                                    generateCell(
                                                        'calc',
                                                        eye.calculation.calculationTable[
                                                            calcRowOffset(eye.calculation) +
                                                                index -
                                                                1
                                                        ],
                                                        indexNonZero,
                                                        ''
                                                    )
                                                "
                                            ></td>
                                            <td class="calcRowCell"></td>
                                            <td
                                                v-for="indexNonZero in 4"
                                                :key="indexNonZero + '2'"
                                                class="calcRowCell text-nowrap"
                                                v-html="
                                                    generateCell(
                                                        'calc',
                                                        eye.calculation.calculationTable[
                                                            calcRowOffset(eye.calculation) +
                                                                index -
                                                                1
                                                        ],
                                                        indexNonZero + 2,
                                                        ''
                                                    )
                                                "
                                            ></td>
                                        </tr>
                                    </table>
                                </div>

                                <div id="calculationFooter">
                                    <div class="calculationFooterLeftContainer">
                                        <div
                                            v-if="
                                                eye.calculation.userSelectedCylinder &&
                                                eye.calculation.calculationType == 'TICL'
                                            "
                                            id="calculationFooterRightTitle"
                                        >
                                            {{ t('preopreport_UserSelCyl') }}
                                        </div>
                                        <div
                                            v-if="eye.calculation.targetLensString"
                                            id="calculationFooterTargetLensString"
                                            class="pt-1"
                                        >
                                            {{
                                                decimalSeparatorFormatter(
                                                    eye.calculation.targetLensString,
                                                    decimalSeparator
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-if="
                                        eye.calculation.calculationTable == undefined ||
                                        eye.calculation.calculationTable.length == 0
                                    "
                                    class="emptyData"
                                >
                                    {{ t('preopreport_CalculationsNotPerformed') }}
                                </div>
                            </div>

                            <div v-if="eye.lenses" id="lensesContainer" class="sectionContainer">
                                <div class="sectionTitle">{{ t('preopreport_Lenses') }}</div>

                                <div class="sectionTable">
                                    <table class="table-lenses">
                                        <template v-if="eye.calculation.calculationType == 'TICL'">
                                            <tr class="headerRow th-7">
                                                <th>{{ t('preopreport_SerialNum') }}</th>
                                                <th>{{ t('preopreport_Model') }}</th>
                                                <th>{{ t('preopreport_Sphere') }}</th>
                                                <th>{{ t('preopreport_Cylinder') }}</th>
                                                <th>{{ t('preopreport_Axis') }}</th>
                                                <th>{{ t('preopreport_Exp_SEQ') }}</th>
                                                <th>{{ t('preopreport_Status') }}</th>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr class="headerRow th-6">
                                                <th>{{ t('preopreport_SerialNum') }}</th>
                                                <th>{{ t('preopreport_Model') }}</th>
                                                <th>{{ t('preopreport_Sphere') }}</th>
                                                <th>{{ t('preopreport_Exp_SEQ') }}</th>
                                                <th>{{ t('preopreport_Status') }}</th>
                                                <th></th>
                                            </tr>
                                        </template>
                                        <!-- eslint-disable vue/no-use-v-if-with-v-for -->
                                        <tr
                                            v-if="
                                                eye.lenses.lensesTable != undefined &&
                                                eye.lenses.lensesTable.length > 0
                                            "
                                            v-for="(lensCell, index) in eye.lenses.lensesTable"
                                            :key="index"
                                        >
                                            <template
                                                v-if="eye.calculation.calculationType == 'TICL'"
                                            >
                                                <td
                                                    v-for="indexNonZero in 6"
                                                    :key="indexNonZero"
                                                    v-html="
                                                        generateCell(
                                                            'lens',
                                                            lensCell,
                                                            indexNonZero,
                                                            undefined,
                                                            indexNonZero !== 2
                                                        )
                                                    "
                                                    class="text-nowrap"
                                                    :class="{
                                                        'text-nowrap': indexNonZero != 6,
                                                    }"
                                                ></td>
                                            </template>
                                            <template v-else>
                                                <td
                                                    v-html="
                                                        generateCell(
                                                            'lens',
                                                            lensCell,
                                                            1,
                                                            undefined,
                                                            false
                                                        )
                                                    "
                                                    class="text-nowrap"
                                                ></td>
                                                <td
                                                    v-html="
                                                        generateCell(
                                                            'lens',
                                                            lensCell,
                                                            2,
                                                            undefined,
                                                            false
                                                        )
                                                    "
                                                    class="text-nowrap"
                                                ></td>
                                                <td
                                                    v-html="generateCell('lens', lensCell, 3)"
                                                    class="text-nowrap"
                                                ></td>
                                                <td v-html="generateCell('lens', lensCell, 6)"></td>
                                            </template>

                                            <td
                                                class="noEmphasis d-flex align-items-center justify-content-between"
                                            >
                                                <div>
                                                    <PrimaryMarker
                                                        v-if="
                                                            generateCell('lens', lensCell, 7) ===
                                                            'Primary'
                                                        "
                                                        class="mr-2"
                                                    />
                                                </div>

                                                {{
                                                    orderStatus(
                                                        definedAndNotBlankArrIndex(lensCell, 8, '—')
                                                    )
                                                }}
                                            </td>
                                        </tr>
                                        <!-- eslint-enable vue/no-use-v-if-with-v-for -->
                                    </table>

                                    <div
                                        v-if="
                                            eye.lenses.lensesTable == undefined ||
                                            eye.lenses.lensesTable.length == 0
                                        "
                                        class="emptyData"
                                    >
                                        {{ t('preopreport_LensesNotOrdered') }}
                                    </div>
                                </div>

                                <template v-if="eye.calculation.outsideRecommendation">
                                    <div class="outsideRecommendation">
                                        {{ lensLengthStr() }}
                                    </div>
                                </template>
                                <template
                                    v-if="
                                        eye.lenses.lensesTable != undefined &&
                                        eye.lenses.lensesTable.length != 0
                                    "
                                >
                                    <div class="piRequired">
                                        {{ t(piRequiredStrId(eye)) }}
                                    </div>
                                </template>
                                <div v-if="eye.acdWarning" class="warningBubble">
                                    {{ t('preopreport_ACDWarning') }}
                                </div>

                                <div v-if="preOpReport.ageWarning" class="warningBubble">
                                    {{ t('preopreport_AgeWarning') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="accuracyWarning">
                {{ t('preopreport_AccuracyWarning') }}
            </div>

            <div id="footerLabel" class="pb-4">
                <img id="footerImg" src="@/assets/StaarSurgical-horiz.png" />
                <div>
                    {{ new Date() | date({format: dateFormat, isUTC: false}) }}
                    &nbsp;&nbsp; | &nbsp;&nbsp;
                    {{ definedAndNotBlankObj(this.currentUser, 'firstName', '—') }}
                    {{ definedAndNotBlankObj(this.currentUser, 'lastName', '—') }}
                </div>
            </div>
            <img id="lensTypeImg" :src="lensTypeImgUrl" />
        </div>
    </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
import {DEFAULT_DATE_FORMAT} from '@/config';
import {
    genderHandling,
    namesFormatter,
    decimalSeparatorFormatter,
    replaceEnclosures,
} from '@/utilities/formatters';
import {definedAndNotBlankObj} from '@/utilities/object';
import SpinnerOverlay from '@/views/layout/SpinnerOverlay';
import {generatePdfDocument, displayPdfDocument} from '@/utilities/pdfUtils';
import {getLogo, preOpReportSelectorList} from '@/constants/report';
import date from '@/filters/date';
import PrimaryMarker from '@/components/PrimaryMarker';

export default {
    name: 'PreOpReport',
    components: {
        SpinnerOverlay,
        PrimaryMarker,
    },
    data() {
        return {
            preOpReport: null,
            preOpReportDebug: {
                firstName: 'Bartholomewz',
                lastName: 'Featherstonethrowz',
                gender: 'M',
                customerOcosId: '987654321z',
                dob: '11/21/1980z',
                yearsOld: '39z',
                patientOcosId: '123456789z',
                clinicName: 'West Coast Clinicz',
                surgeon: 'Dr. John Medinaz',
                surgeonOcosId: '5678901z',
                surgeryDate: '2/2/2022z',
                ageWarning: true,
                eyes: [
                    {
                        name: 'OD',
                        acdWarning: true,
                        calculationVersion: 'v6.00USz',
                        previousInterventions: [
                            'Refractive Surgery (LVC)',
                            'Non-refractive corneal surgery',
                            'IOL, CLE, Cataract',
                        ],
                        preop: {
                            k1: '42.0z',
                            k2: '43.5z',
                            acd: '3.1z',
                            ww: '11.7z',
                            k1Axis: '180.0z',
                            k2Axis: '90.0z',
                            cct: '0.6',
                            manRef: ['-7.0z', '1.5z', '9z', '1z', '2z'],
                            cycRef: ['-7.1z', '1.6z', '8z'],
                            clOver: ['-z1', '-z2', '', null, '-z5'],
                            activeRef: 'CLOR',
                        },
                        calculation: {
                            lensLength: '12.1',
                            cylinderPower: 'z',
                            calculationType: 'TICL',
                            selectedLensIndex: 2,
                            outsideRecommendation: true,
                            userSelectedCylinder: true,
                            targetLensString: 'Myopic EDOF Optic 12.6mm -12.00',
                            calculationTable: [
                                ['-7.z', '+1.5', '-0.12', '-.25', '95', '0.01'],
                                ['-7.z', null, '', '-.25', '95', '0.01'],
                                ['-7.z', '+1.5', null, ''],
                                ['-8.z', '+1.5', '-0.12', null, '', '0.01'],
                                ['-8.5z', '+1.5', '-0.12', '-.25'],
                                ['-7.z', '+1.5', '-0.12', '-.25', '95', '0.01'],
                                ['-7.z', null, '', '-.25', '95', '0.01'],
                                ['-7.z', '+1.5', null, '', '95', '0.01'],
                                ['-8.z', '+1.5', '-0.12', null, '', '0.01'],
                                ['-8.5z', '+1.5', '-0.12', '-.25'],
                                ['+00.00', '+00.00', '+00.00', '+00.00', '000', '+00.00'],
                                ['+00.00', '+00.00', '+00.00', '+00.00', '000', '+00.00'],
                                ['+00.00', '+00.00', '+00.00', '+00.00', '000', '+00.00'],
                                ['+00.00', '+00.00', '+00.00', '+00.00', '000', '+00.00'],
                                ['+00.00', '+00.00', '+00.00', '+00.00', '000', '+00.00'],
                            ],
                        },
                        lenses: {
                            lensesTable: [
                                [
                                    'T1234z',
                                    'VTICM5_{{12.6}}',
                                    '{{-7.00}}',
                                    '{{2.00}}',
                                    '90',
                                    '0.15',
                                    'Ordered',
                                ],
                                ['T1234567z', 'VTICM5_12.6', '-7.00', null, '', '0.01', 'Reserved'],
                                ['T1234567z', 'VTICM5_12.6', '-7.00', '2.00', null, '', 'Reserved'],
                                ['T1234567z', 'VTICM5_12.6', '-7.00', '2.00', '90'],
                                //['T1234z', 'VTICM5_12.6', '-7.00', '2.00', '90', '0.15', 'Ordered'],
                                //['T1234567z', 'VTICM5_12.6', '-7.00', null, '', '0.01', 'Reserved'],
                                //['T1234567z', 'VTICM5_12.6', '-7.00', '2.00', null, '', 'Reserved'],
                                //['T1234567z', 'VTICM5_12.6', '-7.00', '2.00', '90'],
                            ],
                        },
                    },
                    {
                        name: 'OS',
                        calculationVersion: 'v6.00USz',
                        preop: {
                            k1: '42.0z',
                            k2: '',
                            acd: null,
                            ww: '11.7z',
                            k1Axis: '180.0z',
                            k2Axis: '90.0z',
                            cct: 0.45,
                            manRef: ['-7.0z', '', '9z', '1z'],
                            cycRef: ['-7.1z', '1.5z', null, '1z'],
                            clOver: ['-1z', null, '-3z'],
                        },
                        calculation: {
                            lensLength: '',
                            cylinderPower: '',
                            calculationType: 'ICL',
                            selectedLensIndex: null,
                            calculationTablez: [],
                        },
                        lenses: {
                            lensesTable: [],
                        },
                    },
                ],
            },
        };
    },
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            preOpReportRemote: 'preopreport/preOpReport',
            zone: 'zone/currentZone',
        }),
        ...mapGetters('user', ['activeCustomer', 'currentUser']),
        dateFormat() {
            return `${DEFAULT_DATE_FORMAT} HH:mm`;
        },
        decimalSeparator() {
            const {decimalSeparator} = this.currentUser;
            return decimalSeparator;
        },
        preOpDataSetId() {
            return this.$route.params.preOpDataSetId;
        },
        lensTypeImgUrl() {
            const brandLogo = getLogo(this.preOpReport.brandLogo);
            return require(`@/assets/reports/${brandLogo}.png`);
        },
    },
    props: {
        debug: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        genderHandling,
        definedAndNotBlankObj,
        decimalSeparatorFormatter,
        namesFormatter,
        date,

        ...mapMutations({
            showWarningModal: 'message/showWarningModal',
        }),

        /** Generate a pdf report and display it in an iframe */
        async printReport() {
            const pdfBlob = await generatePdfDocument(
                '#main',
                preOpReportSelectorList,
                this.t('reportTitle'),
                this.$html2canvas,
                this.debug
            );
            displayPdfDocument(pdfBlob);
        },

        definedAndNotBlankArrIndex(array, indexNonZero, failStr, blankArrayString) {
            var index = indexNonZero - 1;

            if (array == undefined) return failStr;

            if (array.length <= index) {
                if (blankArrayString != undefined) return blankArrayString;

                return failStr;
            }

            if (typeof array[index] == 'string' && array[index].length == 0) return failStr;

            if (array[index] == undefined) return failStr;

            return array[index];
        },

        //This function is used to determine what the starting index should be for calculations
        calcRowOffset(calculation) {
            //If the calc table is less than 5 then return 0
            if (calculation.calculationTable.length < 5) return 0;

            //If the selectedLensIndex is less than 2 then we're at the calc list start
            if (calculation.selectedLensIndex < 2) return 0;

            //If we're at the end of the calc list then we need to be 5 minus list length
            if (
                calculation.selectedLensIndex == calculation.calculationTable.length - 2 ||
                calculation.selectedLensIndex == calculation.calculationTable.length - 1
            )
                return calculation.calculationTable.length - 5;

            //Otherwise just use 2 minus selected lens index
            return calculation.selectedLensIndex - 2;
        },

        //This function is used to determine what the onscreen selection index should be (1-5)
        //selectedLensIndex is zero-based whereas the v-for index (return value) is not.
        onScreenRowSelectionIndex(calculation) {
            //Display before the middle element
            if (calculation.selectedLensIndex == 0) return 1;
            if (calculation.selectedLensIndex == 1) return 2;

            //If the onscreen table won't be filled then handle for the 3rd and 4th index
            if (calculation.calculationTable.length < 5 && calculation.selectedLensIndex == 2)
                return 3;

            if (calculation.calculationTable.length < 5 && calculation.selectedLensIndex == 3)
                return 4;

            //If we're at the end of the calculations, then show at the end
            if (calculation.selectedLensIndex == calculation.calculationTable.length - 2) return 4;
            if (calculation.selectedLensIndex == calculation.calculationTable.length - 1) return 5;

            //We're not at the start or the end, and there are more calculations than will be displayed.
            // so the middle selection is 3.
            return 3;
        },
        orderStatus: function (value) {
            switch (value) {
                case 'Shipped':
                    return this.t('shipped');
                case 'Reserved':
                    return this.t('reserved');
                case 'Reserved-Approved':
                    return this.t('reservedApproved');
                case 'Ordered':
                    return this.t('ordered');
                case 'Reserved-Hold':
                    return this.t('reservedHol');
                case 'Delivered':
                    return this.t('delivered');
            }

            return value;
        },
        handleForMicronConversion(value) {
            if (isNaN(value)) return value;
            else return value * 1000;
        },
        lensLengthStr() {
            return this.t(`preopreport_${this.zone}_DfuRecommendedLength`);
        },
        handleForRedText(text) {
            let output = replaceEnclosures(text, '<div class="text-red d-inline-block">', '</div>');
            return [output.textWithoutEnclosures, output.enclosuresReplaced];
        },
        unitsForCellIndex(tableName, index, highlight = false) {
            let className = 'dUnit';
            if (highlight) className = 'dUnitRed';

            let dOutput = `<div class="${className}">&nbsp;D</div>`;

            switch (tableName) {
                case 'preop':
                    switch (index) {
                        case 1:
                        case 2:
                            return dOutput;
                        case 3:
                            return '°';
                    }
                    break;
                case 'lens':
                    switch (index) {
                        case 3:
                        case 4:
                            return dOutput;
                        case 5:
                            return '°';
                    }
                    break;
                case 'calc':
                    switch (index) {
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            return dOutput;
                        case 5:
                            return '°';
                    }
                    break;
            }

            return '';
        },
        generateCell(tableName, data, index, blankArrayString = undefined, useFormatter = true) {
            let output = '';
            let highlight = false;

            if (tableName == 'lens') {
                let outputArr = this.handleForRedText(
                    this.definedAndNotBlankArrIndex(data, index, '—')
                );

                output = outputArr[0];

                if (outputArr[1]) highlight = true;
            } else output = this.definedAndNotBlankArrIndex(data, index, '—', blankArrayString);

            if (output != '—' && output.length > 0) {
                output = useFormatter
                    ? decimalSeparatorFormatter(output, this.decimalSeparator)
                    : output;
                output = output += this.unitsForCellIndex(tableName, index, highlight);
            }
            return output;
        },
        piRequiredStrId(eye) {
            let strName = '';

            if (eye.piRequired) {
                if (eye.lenses.lensesTable.length > 1) strName = 'preopreport_PiRequiredPlural';
                else strName = 'preopreport_PiRequired';
            } else {
                if (eye.lenses.lensesTable.length > 1) strName = 'preopreport_NoPiRequiredPlural';
                else strName = 'preopreport_NoPiRequired';
            }

            return strName;
        },
    },

    async mounted() {
        if (this.preOpDataSetId == undefined || this.preOpDataSetId == 0) {
            //We have a problem so alert
            //Todo - Does this go to toast?
            alert('Error: The preOpDataSetId is empty');
        } else if (this.preOpDataSetId == 'zzz') {
            this.preOpReport = this.preOpReportDebug;
        } else {
            await this.blockingRequest('preopreport/fetchPreOpReport', {
                preOpDataSetId: this.preOpDataSetId,
            });

            this.preOpReport = this.preOpReportRemote;
        }
        if (!this.debug) {
            var styleSheet = document.createElement('style');
            styleSheet.innerText = `
                body {
                    overflow: hidden;
                }
            `;
            document.head.appendChild(styleSheet);

            setTimeout(async () => {
                this.printReport();
            }, 500);
        }
    },
};
</script>
<style lang="scss" scoped>
@import '../../assets/css/variables';

$column-width: 695px;

#generatingReportContainer {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: white;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

#printReportBtn {
    position: absolute;
    z-index: 2000;
}

#main {
    padding-left: 50px;
    background-color: white;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1500px;
    font-size: 1.3rem;
    /*opacity: 0;*/
    z-index: 0;
    /*display: none;*/
}

img#headerImg {
    margin-top: 25px;
    margin-bottom: 20px;
    width: 235px;
}

img#footerImg {
    width: 424px;
    padding-top: 21px;
    padding-bottom: 16px;
}

.eyeResultHeaderImg {
    width: 50px;
    margin-right: 12px;
    height: 60px;
    max-height: 60px;
    margin-top: 20px;
}

#accuracyWarning {
    font-size: 1.3rem;
    padding: 22px 110px 20px 110px;
    text-align: center;
    width: 1385px;
    color: rgba(0, 0, 0, 0.7);
}

.previousInterventionsContainer {
    width: 100%;
    margin-right: 9px;
    margin-top: 9px;
    text-align: center;
    font-size: 1rem;
    margin-top: auto;
    margin-bottom: auto;
}

div#headerRight {
    font-size: 2.14rem;
    color: #5b5b5b;
    font-weight: bold;
    margin-right: 62px;
    float: right;
    margin-top: 20px;
}

div#titleContainer {
    border-bottom: 5px solid black;
    padding: 0px 0px 10px 0px;
    margin-right: 62px;
}

.names {
    color: #616469;
    font-size: 1.857rem;
}

div#patientDataCol2 {
    display: inline-block;
}

div#patientDataCol1 {
    float: left;
    width: 670px;
}

.patientDataItem {
    padding: 5px 0px;
    clear: both;
}

.patientDataItem:first-of-type {
    padding-top: 15px;
}

.preopDataItem {
    padding: 10px 0px;
    clear: both;
}

#preopDataCol1 {
    padding-left: 25px;
    width: 275px;
    float: left;
}

#preopDataCol2 {
    padding-left: 25px;
    width: 215px;
    float: left;
}

.patientDataLabel {
    color: #616469;
    float: left;
    width: 160px;
}

.preopDataLabel {
    color: grey;
    float: left;
    width: 100px;
}

.eyeContainer {
    width: $column-width;
    vertical-align: top;
    display: table-cell;
}

.eyeContainer:first-of-type {
    /*padding-right: 40px;*/
}

.patientDataValue {
    float: left;
    font-weight: bold;
}

.noEmphasis {
    color: grey;
}

.eyeTitle {
    font-size: 5rem;
    font-weight: bold;
    margin-right: 42px;
    margin-left: 13px;
    margin-top: 12px;
    margin-bottom: auto;
}

.sectionTitle {
    font-size: 3.857rem;
    font-weight: 500;
    margin-left: 12px;
    padding-top: 10px;
}

.eyeResultHeader {
    min-height: 94px;
    border-bottom: 5px solid black;
    display: flex;
}

.calculationTitleContainer {
    margin-top: 25px;
    margin-right: 10px;
    font-size: 1rem;
    text-align: right;
}

#resultsContainer {
    padding-top: 40px;
    height: fit-content;
    display: table;
}

#resultsContainerRow {
    display: table-row;
}

th {
    color: rgba(0, 0, 0, 0.5);
    font-size: 1rem;
}

.sectionTable {
    padding: 10px;
    max-width: $column-width;
}

table {
    width: 100%;
    border-spacing: 0px;
    border-collapse: collapse;
    font-size: inherit;
}

tr.headerRow {
    background-color: #f9f9f9;
}

td {
    font-weight: bold;
    text-align: center;
}

th,
td {
    padding: 10px;
}

div#calculationContainer {
    clear: both;
    min-height: 483px;
}

.preopDataValue {
    font-weight: bold;
}

th.calculationTableHeader1 {
    color: black;
    border-bottom: 3px solid black;
    font-size: 1.07rem;
    font-weight: normal;
}

tr.selectedCalcRow {
    border: 3px solid black;
    background-color: #f1f1f1;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
}

.emptyData {
    color: #aeaeae;
    font-size: 1.7rem;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 167px;
}

.eyeResultHeaderImgBlack {
    margin-right: 12px;
    height: 42px;
    margin-top: 30px;
    min-width: 50px;
    background-image: url('../../assets/eye_icon_black.svg');
    background-size: cover;
}

.eyeResultHeaderImgGray {
    margin-right: 12px;
    height: 44px;
    margin-top: 30px;
    min-width: 50px;
    background-image: url('../../assets/eye_icon_gray.svg');
    background-size: cover;
}

div#patientDataContainer {
    padding-top: 8px;
}

div#pageHeader {
    margin: 40px 0px 5px 0px;
}

div#calculationFooterRightTitle,
#calculationFooterRightValue {
    float: right;
    margin-right: 11px;
}

#calculationFooterRightValue {
    font-weight: bold;
}

div.calculationFooterLeftContainer {
    margin-left: 15px;
}

.piRequired {
    font-size: 1.3rem;
    font-weight: 400;
    font-weight: bold;
    padding-left: 18px;
    color: red;
}

.outsideRecommendation {
    color: red;
    font-size: 1.3rem;
    font-weight: 400;
    font-weight: bold;
    padding-left: 18px;
}

#calculationFooterTargetLensString {
    font-weight: bold;
    font-size: 1.3rem;
}

td {
    text-align: center;
}

th {
    text-align: center;
}

.preopRowTitle {
    text-align: left;
    font-weight: normal;
}

#whiteSpacer {
    width: 25px;
}

#table1 {
    margin-bottom: 30px;
}

.th-7 th {
    max-width: 100px;
}

.th-6 th {
    max-width: 115px;
}

.preopDataValue > .noEmphasis {
    font-weight: normal;
}

#lensesContainer {
    min-height: 366px;
}

.calcRowCell {
    height: 47px;
}

.eyeResult {
    border: 5px solid black;
    height: 100%;
    text-align: left;
    min-height: 1650px;
}

#footerLabel {
    height: 135px;
    float: left;
}

.warningBubble {
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), #f3aa1c;
    width: 97%;
    margin: 10px;
    padding: 20px 20px 15px 20px;
    font-size: 1.3rem;
    text-align: center;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
}

#printBtn {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
}

.degAdjustment {
    margin-left: -3px;
}

#lensTypeImg {
    width: 175px;
    margin-right: 58px;
    margin-top: 7px;
    float: right;
}
.z-index-999 {
    z-index: 999;
}
</style>
<style lang="scss">
.dUnit,
.dUnitRed {
    font-weight: normal;
    display: inline-block;
    font-size: 1rem;
}

.dUnitRed {
    color: red;
}

.cellRedTextFound {
    color: red;
    display: inline-block;
}

#generatingReport {
    font-size: 50px;
}
</style>
